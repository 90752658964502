global?.modules?.set({
  name: 'swiper',
  is_booted: false,
  async init() {
    if (this.is_booted) {
      return;
    }
    this.is_booted = true;

    initAboutNumbersSwiper();
    initGalleryMediaSwiper();
    initMultiCasesSwiper();
    initResoucesMediaSwiper();
  }
});

function initAboutNumbersSwiper() {
  const containers = document.querySelectorAll('.s-about-numbers .swiper');
  if (!containers.length) return;

  containers.forEach((container) => {
    new Swiper(container, {
      slidesPerView: 4,
      spaceBetween: 12,
      allowTouchMove: true,
      speed: 3000,
      loop: true,
      autoplay: {
        delay: 0,
        disableOnInteraction: false
      },
      breakpoints: {
        375: { spaceBetween: 14 },
        390: { slidesPerView: 4, spaceBetween: 18 },
        620: { slidesPerView: 6, spaceBetween: 24 },
        992: { slidesPerView: 6, spaceBetween: 48 },
        1440: { slidesPerView: 6, spaceBetween: 96 }
      }
    });
  });
};

function initResoucesMediaSwiper() {
  const containers = document.querySelectorAll('.s-resources .swiper');
  if (!containers.length) return;

  containers.forEach((container) => {
    const instance = new Swiper(container, {
      slidesPerView: 4,
      spaceBetween: 0,
      speed: 3000,
      loop: true,
      // autoplay: window.innerWidth >= 778 ? { delay: 0, disableOnInteraction: false } : false,
      allowTouchMove: true,
      navigation: {
        prevEl: '.s-resources__content-arrow.s-resources-content__arrow-prev',
        nextEl: '.s-resources__content-arrow.s-resources-content__arrow-next'
      },
      breakpoints: {
        0: { slidesPerView: 1 },
        390: { slidesPerView: 2 },
        992: { slidesPerView: 4 }
      }
    });

    // Перевірка ширини вікна для старту/зупинки автопрокрутки
    // window.addEventListener('resize', () => {
    //   const isDesktop = window.innerWidth >= 778;
    //   if (isDesktop && !instance.autoplay.running) {
    //     instance.params.autoplay = { delay: 0, disableOnInteraction: false };
    //     instance.autoplay.start();
    //   } else if (!isDesktop && instance.autoplay.running) {
    //     instance.autoplay.stop();
    //   }
    // });
  });
};

function initGalleryMediaSwiper() {
  const containers = document.querySelectorAll('.s-gallery-media .swiper');
  if (!containers.length) return;

  containers.forEach((container) => {
    new Swiper(container, {
      slidesPerView: 3,
      spaceBetween: 4,
      speed: 3000,
      loop: true,
      // autoplay: {
      //   delay: 500,
      //   disableOnInteraction: false
      // },
      navigation: {
        prevEl: '.s-gallery-media__content-arrow.s-gallery-media-content__arrow-prev',
        nextEl: '.s-gallery-media__content-arrow.s-gallery-media-content__arrow-next'
      },
      allowTouchMove: true
    });
  });
};

function initMultiCasesSwiper() {
  let swiperInstances = [];
  const galleryListEls = document.querySelectorAll('.s-process__item__gallery-list');
  const galleryColumnEls = document.querySelectorAll('.s-process__item__column--gallery');

  function initSwipers() {
    if (!galleryListEls.length || !galleryColumnEls.length) return;

    window.innerWidth <= 768 ? initializeMobileSwipers() : destroySwipers();
  }

  function initializeMobileSwipers() {
    if (!swiperInstances.length) {
      galleryListEls.forEach((galleryListEl, index) => {
        const galleryColumnEl = galleryColumnEls[index];
        if (!galleryColumnEl) return;

        const rows = getGridRows(galleryListEl);
        const swiper = createSwiper(galleryListEl, rows);

        swiperInstances.push(swiper);
        updateGalleryHeight(galleryColumnEl, rows);
      });
    } else {
      swiperInstances.forEach(swiper => swiper.update());
    }
  }

  function createSwiper(galleryListEl, rows) {
    return new Swiper(galleryListEl.closest('.swiper'), {
      spaceBetween: 4,
      allowTouchMove: true,
      slidesPerView: 4,
      grid: {
        rows
      }
    });
  }

  function destroySwipers() {
    swiperInstances.forEach(swiper => swiper.destroy(true, true));
    swiperInstances = [];
    galleryColumnEls.forEach((el) => (el.style.height = ''));
  }

  function getGridRows(galleryListEl) {
    const childrenCount = galleryListEl.childElementCount;
    return Math.min(Math.ceil(childrenCount / 8), 4);
  }

  function updateGalleryHeight(galleryColumnEl, rows) {
    galleryColumnEl.style.height = `${rows * 84}px`;
  }

  initSwipers();
  window.addEventListener('resize', initSwipers);
}